button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-family: Inter, serif;
  @media (max-width: 451px) {
    font-size: 16px;
  }

  @media (min-width: 452px) and (max-width: 953px) {
    font-size: 19px;
  }

  @media (min-width: 954px) {
    font-size: 20px;
  }
}

button:hover {
  color: rgba(255, 255, 255, 0.4);
}

.soundButtonActive {
  color: rgba(255, 255, 255, 0.4);
}

.soundButton {
  color: white;
}

.logo {
  @media (max-width: 953px) {
    width: 117px;
    height: 54px;
  }

  @media (min-width: 954px) {
    width: 127px;
    height: 64px;
  }
}

.logoHided {
  visibility: hidden;
  @media (max-width: 451px) {
    width: 117px;
    height: 54px;
  }
}

.openedHeader {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  overflow: scroll;

  @media (max-width: 451px) {
    background: #000;
  }

  @media (min-width: 452px) and (max-width: 953px) {
    min-height: 120px;
  }

  @media (min-width: 954px) {
    min-height: 143px;
    overflow: auto;
  }
}

.closedHeader {
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.54) 65%, rgba(0, 0, 0, 0.765) 99%) no-repeat;

  @media (max-width: 451px) {
    min-height: 100px;
  }

  @media (min-width: 452px) and (max-width: 953px) {
    min-height: 120px;
  }

  @media (min-width: 954px) {
    min-height: 143px;
  }
}

.headerIconsHolder {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 451px) {
    width: 95%;
  }

  @media (min-width: 452px) {
    width: 85%;
  }
}

.headerIconsHolderActive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 451px) {
    width: 95%;
    min-height: 100px;
  }

  @media (min-width: 452px) and (max-width: 953px) {
    width: 85%;
    min-height: 120px;
  }

  @media (min-width: 954px) {
    width: 85%;
    min-height: 143px;
  }
}

.burgerLineOneOpened {
  margin-bottom: 5px;
  background: white;
  width: 3rem;
  height: 0.2rem;
  transform: rotate(45deg);
  transform-origin: 16px;
  transition: all 0.3s linear;
}

.burgerLineOneClosed {
  margin-bottom: 5px;
  background: white;
  width: 3rem;
  height: 0.2rem;
  transform: rotate(0);
  transition: all 0.3s linear;
}

.burgerLineTwoOpened {
  background: white;
  width: 3rem;
  height: 0.2rem;
  transform: rotate(-45deg);
  transform-origin: 21px;
  transition: all 0.3s linear;
}

.burgerLineTwoClosed {
  background: white;
  width: 3rem;
  height: 0.2rem;
  transform: rotate(0);
  transition: all 0.3s linear;
}

.soundButtonsHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.soundButtonsHolderHided {
  visibility: hidden;
}

.soundButtonsText {
  color: white;
  margin-bottom: 5px;
  margin-top: 10px;
  font-family: 'Inter', serif;

  @media (max-width: 451px) {
    font-size: 16px;
  }

  @media (min-width: 452px) and (max-width: 953px) {
    font-size: 19px;
  }

  @media (min-width: 954px) {
    font-size: 20px;
  }
}

.soundButtonsTextSpan {
  color: white;

  @media (max-width: 451px) {
    font-size: 16px;
  }

  @media (min-width: 452px) and (max-width: 953px) {
    font-size: 19px;
  }

  @media (min-width: 954px) {
    font-size: 20px;
  }
}
