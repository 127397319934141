.openedNavigationHolder {
  width: 100%;
  font-family: 'Inter', serif;
  font-size: 19px;
  color: white;
  visibility: visible;
}

.closedNavigationHolder {
  visibility: hidden;
}

.navListHolder {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  top: 0;

  @media (max-width: 451px) {
    margin-left: 12%;
  }

  @media (min-width: 452px) and (max-width: 834px) {
    margin-left: 41%;
  }

  @media (min-width: 835px) and (max-width: 953px) {
    margin-left: 45%;
  }

  @media (min-width: 954px) and (max-width: 1023px) {
    margin-left: 42%;
  }

  @media (min-width: 1024px) and (max-width: 1074px) {
    margin-left: 47%;
  }

  @media (min-width: 1075px) and (max-width: 1279px) {
    margin-left: 43%;
  }

  @media (min-width: 1280px) and (max-width: 1291px) {
    margin-left: 48%;
  }

  @media (min-width: 1291px) and (max-width: 1439px) {
    margin-left: 45%;
  }

  @media (min-width: 1440px) {
    margin-left: 52%;
  }
}

.navListItem {
  list-style-type: none;
  margin-bottom: 16px;
}

.divider {
  border-bottom: 1px solid #d9d9d9;
  @media (max-width: 451px) {
    margin: 10px 5px;
  }

  @media (min-width: 452px) {
    margin: 30px 10px;
  }
}

.infoHolder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 451px) {
    margin: 0 auto;
    width: 80%;
    height: auto;
  }

  @media (min-width: 452px) and (max-width: 953px) {
    margin-left: 52%;
    width: 40%;
    height: auto;
  }

  @media (min-width: 954px) and (max-width: 1291px) {
    margin-left: 52%;
    width: 40%;
    height: auto;
  }

  @media (min-width: 1292px) and (max-width: 1439px) {
    margin-left: 53%;
    width: 40%;
    height: auto;
    max-height: 340px;
  }

  @media (min-width: 1440px) {
    margin-left: 55%;
    width: 392px;
    height: auto;
    max-height: 340px;
  }
}

.info {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
}

.projectLinksHolder {
  height: 98px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Inter', serif;

  @media (max-width: 451px) {
    height: auto;
    width: 80%;
    margin: 0 auto;
    font-size: 24px;
  }

  @media (min-width: 452px) and (max-width: 1291px) {
    margin-left: 52%;
    width: 40%;
    font-size: 24px;
    height: auto;
  }

  @media (min-width: 1292px) and (max-width: 1439px) {
    margin-left: 53%;
    width: 40%;
    height: auto;
    max-height: 340px;
  }

  @media (min-width: 1440px) {
    margin-left: 55%;
    width: 40%;
    height: auto;
    max-height: 340px;
  }
}

.projectLinks {
  text-decoration: none;
  color: white;
}

.navButton {
  font-family: 'Inter', serif;
  color: white;

  @media (max-width: 451px) {
    font-size: 36px;
  }
  // was 834px
  @media (min-width: 452px) and (max-width: 953px) {
    font-size: 40px;
  }

  @media (min-width: 954px) and (max-width: 1291px) {
    font-size: 56px;
  }

  @media (min-width: 1292px) and (max-width: 1439px) {
    font-size: 56px;
  }
  @media (min-width: 1440px) {
    font-size: 56px;
  }
}

.navButtonActive {
  font-family: 'Inter', serif;
  color: white;
  font-weight: bold;

  @media (max-width: 451px) {
    font-size: 36px;
  }

  @media (min-width: 452px) and (max-width: 953px) {
    font-size: 40px;
  }

  @media (min-width: 954px) and (max-width: 1291px) {
    font-size: 56px;
  }
  @media (min-width: 1292px) and (max-width: 1439px) {
    font-size: 56px;
  }
  @media (min-width: 1440px) {
    font-size: 56px;
  }
}

.mailToLink {
  text-decoration: none;
  font-family: 'Inter', serif;
  color: white;
  @media (max-width: 451px) {
    font-size: 36px;
  }

  @media (min-width: 452px) and (max-width: 834px) {
    font-size: 40px;
  }

  @media (min-width: 835px) {
    font-size: 56px;
  }
}

.mailToLink:hover {
  color: rgba(255, 255, 255, 0.4);
}
