video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.holder {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: black;
}

.footer {
  position: absolute;
  width: 127px;
  height: 64px;
  bottom: 0;
  right: 1rem;
  display: flex;
  justify-content: space-around;
}

.footerHided {
  display: none;
}
